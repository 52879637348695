@import '@styles/mixins.style';

.ra-shopping-cart {
  position: absolute;
  top: 50px;
  right: -24px;
  width: 95vw;
  padding: var(--ra-spacing-2);
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  border-radius: var(--ra-border-radius-xl);
  padding-top: 0;
  display: none;

  @include breakpoint(sm) {
    width: 416px;
  }

  @include breakpoint(md) {
    right: 24px;
  }

  &__list {
    overflow: auto;
    max-height: 50vh;
  }

  &.open {
    opacity: 1 !important;
    display: inline !important;
    pointer-events: all;
  }

  &__item {
    height: 48px;
    padding: var(--ra-spacing-2);
    border-radius: var(--ra-border-radius-xl);

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--ra-spacing-4);

      span {
        flex-grow: 1;
      }

      &:focus-visible {
        background-color: var(--color-neutral-3);
      }
    }

    img {
      width: 64px;
      height: 48px;
      object-fit: cover;
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }

    button {
      min-width: 48px;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--color-neutral-3);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-2);
    padding: var(--ra-spacing-2);
  }
}
